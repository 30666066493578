import VueRouter from 'vue-router'

import VideoPlayer from './components/VideoPlayer'

const routes = [
  { path: '/', component: VideoPlayer },
  { path: '*',  redirect: "/"}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router