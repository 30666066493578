<template>
  <amplify-authenticator>
  <v-app>    
    <v-main style="margin-top: 0px">  
      <v-container class="main-bg" fluid padless>
        <div class="container">        
            <div style="margin-left:30px" class="inner">           
            <div style="margin-left:-30px" class="player-wrapper" >
              <div class="aspect-spacer"></div>
              <div class="pos-absolute full-width full-height top-0">
                <video id="video-player" class="el-player" poster="./../assets/coming-soon-black.png" controls playsinline></video>
              </div>
            </div> 
            <div class="custom-container">
                <div class="text-event">
                    <h3>For people based in China / Indonesia <strong>AND</strong> working remotely, please use this 
                      <strong>
                        <a :href="teamsUrl" target="_blank" rel="noopener">link</a>
                      </strong>
                    </h3>
                </div>
              </div>
          </div>          
        </div>           
      </v-container>
    </v-main>   
  </v-app>
  </amplify-authenticator>
</template>

<script>
import {
  PlayerEventType,
  PlayerState
} from "amazon-ivs-player";

export default {
  name: "Player",
  props: {
    player: null,
  },
  data() {
    return {
      teamsUrl: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWQwMjdkNTEtMTYwYS00ZDAwLWJlMDMtNTE2MTRmMDZmMWU5%40thread.v2/0?context=%7b%22Tid%22%3a%22aec0543a-cc73-4fa4-9e2a-cf52adf9b788%22%2c%22Oid%22%3a%22ef40ac8f-5e8c-43d7-94eb-70e2b8cf196c%22%7d"
    }
  },
  mounted() {
   
    const IVSPlayer = document.getElementById("ivsPlayer");
    IVSPlayer.async = true;

    const player = window.IVSPlayer.create();
    console.log("IVS Player version:", player.getVersion());
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    player.addEventListener(PlayerState.PLAYING, function() {
      console.log("Player State - PLAYING");
    });

   
    player.addEventListener(PlayerState.READY, function() {
      console.log("Player State - READY");
    });

    player.addEventListener(PlayerEventType.ERROR, function(err) {
      console.warn("Player Event - ERROR:", err);
    });

    player.setAutoplay(true);
    player.load(process.env.VUE_APP_AWS_IVS_PLAYBACK_URL);
    player.setVolume(0.5);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  computed: {
  },
  methods: {    
    videoFullScreen() {
      let elem = document.getElementById("video-player");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    },
 },
};
</script>

<style scoped>
*,
*::before,
*::after {
  box-sizing: border-box;
}
ul[class],
ol[class] {
  padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", sans-serif;
  user-select: none;  
}
ul[class],
ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
article > * + * {
  margin-top: 1em;
}
input,
button,
textarea,
select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
#app {
  background: #00427F;
  height: 100%;
  margin-top: -0px !important;
  padding: 0 !important;
}
#video-player:-webkit-full-screen  {
  width: 100%;
  height: 100%;
}
.main-bg {
  padding-top: 80px;
  padding-bottom: 150px;
  background-image: url(./../assets/main-bg.png);
  background-size: cover;
  /* padding: 0 !important; */
}
.font-size4rem {
  font-size: 4rem
}
.font-size7rem {
  font-size: 7rem
}
.custom-container {
  position: relative;
  width: 100%;
}
.text-event {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
  padding: 0;
  margin-top: 15px;
}
.inner {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  margin: 0 auto;
  padding-top: 1px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
}
.player-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  border-radius: var(--radius);
  border: 5px solid beige;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  z-index: 1;
  margin-left: -30px;
}
.aspect-spacer {
  padding-bottom: 65.25%;
}
.el-player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: var(--radius);
}
video {
  width: 100%;
  border-radius: var(--radius);
  background: #000;
}

h2 {
  font-size: 25px;
  text-align: center;
  padding-bottom: 20px;
}

#waiting {
  top: 100px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
}
.waiting-text {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #d5dbdb;
}
.float {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}
.pos-absolute {
  position: absolute !important;
}
.pos-fixed {
  position: fixed !important;
}
.pos-relative {
  position: relative !important;
}
.top-0 {
  top: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.show {
  transition: opacity 180ms;
}
.hide {
  opacity: 0;
}
@keyframes blink {
  50% {
    opacity: 0.8;
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 18px;
    padding-bottom: 5px !important;
  }
  .card {
    top: -270px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: -20px !important;
  }
  .answer {
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    display: flex;
    text-decoration: none;
    border: 1px solid #d5dbdb;
    border-radius: 50px;
    padding: 0 24px;
    margin: 10px 0;
    background: #fafafa;
    color: #545b64;
    transition: all .05s ease-in-out;
  }
  .inner {
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: stretch;
    margin: 0 auto;
    padding: 15px 0 0 0 !important;
  }
  .font-size4rem {
    font-size: 2rem
  }
  .font-size7rem {
    font-size: 4rem
  }
  .clinops-logo {
    width: 220px !important;
  }
  .v-toolbar__content {
    padding-left: 0 !important;
  }
  .text-event {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    color: white;
    text-align: center;
    padding: 0;
    margin-top: 20px !important;
  }
}
@media (min-width: 767px) {
  .card {
    top: -135%;
  }
}
</style>
