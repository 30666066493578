import Vue from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify'
import '@aws-amplify/ui-vue';
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_AWS_COGNITO_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_AWS_COGNITO_APP_USER_POOL_CLIENT_ID,
    oauth: {
        domain: process.env.VUE_APP_AWS_COGNITO_APP_USERPOOL_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code'
    }
  }
});

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Amplify)

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
